<template>
  <div class="app-container">
    <div class="top">
      <div class="top-left">
        <el-autocomplete
          class="top-left-input"
          v-model="query.Name"
          placeholder="请输入专业名称"
          value-key="SpecialtyName"
          :fetch-suggestions="searchResultList"
          @select="handleSelect"
        ></el-autocomplete>
        <el-button class="top-left-button" @click="showSearchSpecialtyList" type="primary">搜索</el-button>
      </div>
      <div class="top-right">
        <span class="top-right-title">热门专业:</span>
        <ul class="top-right-box">
          <li class="top-right-box-item" @click="openSpecialtyDetail(item)" v-for="(item, index) in hotSpecialtyList" :key="index">
            {{item.SpecialtyName}}
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="content-top">
        <div class="content-top-name">专业分类</div>
        <div class="content-top-box">
          <div class="content-top-box-item" :class="{'is-active':isActive}" @click="lookBK">本科专业</div>
          <div class="content-top-box-item" :class="{'is-active':!isActive}" @click="lookZK">专科专业</div>
        </div>
      </div>
      <div class="content-box">
        <div class="main" v-for="(item, index) in mainList" :key="index">
          <div class="main-name">{{item.CategoryName}}</div>
          <ul class="main-box">
            <li class="main-box-item" @click="openSpecialtyList(subItem)" v-for="(subItem, subIndex) in item.Children" :key="subIndex">
              {{subItem.CategoryName}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { SpecialtyCategoryList, SpecialtySearch, SpecialtyHotList} from '@/api/search_specialty'
  import API from '@/api/config'
  export default {
    name: "Search-Specialty",
    data(){
      return{
        mainData: {},
        mainList: [],
        hotSpecialtyList: [],
        isActive: true,
        query:{
          Name: '',
          PageIndex: 1,
          PageSize: 999999
        }
      }
    },
    mounted(){
      this.initialize()
    },
    methods:{
      //打开专业详情
      openSpecialtyDetail(item){
        this.$router.push({
          path: '/specialty-detail',
          query:{
            SpecialtyCode: item.SpecialtyCode
          }
        })
      },
      //展示搜索的专业列表
      showSearchSpecialtyList(){
        if(!this.query.Name.trim()) return
        this.$router.push({
          path: '/specialty-list',
          query:{
            Name:this.query.Name
          }
        })
      },
      //专业详情
      handleSelect(item){
        this.$router.push({
          path: '/specialty-detail',
          query:{
            SpecialtyCode: item.SpecialtyCode
          }
        })
      },
      //返回搜索的专业列表
      async searchResultList(name, callback){
        if(!name.trim()) return
        callback((await API.Specialty.SpecialtySearch(this.query)).data.Data)
      },
      //专业具体种类列表
      openSpecialtyList(subItem){
        this.$router.push({
          path: '/specialty-list',
          query: {
            CategoryCode: subItem.CategoryCode,
            CategoryName: subItem.CategoryName
          }
        })
      },
      //查看本科专业分类
      lookBK() {
        this.isActive = true
        this.mainList = this.mainData.CategoryListBK
      },
      //查看专科专业分类
      lookZK() {
        this.isActive = false
        this.mainList = this.mainData.CategoryListZK
      },
      //初始化数据
      initialize () {
        this.getMainList()
        this.getHotSpecialty()
      },
      //获取专业主页列表数据
      async getMainList () {
        this.mainData = (await API.Specialty.SpecialtyCategoryList()).data
        this.mainList = this.mainData.CategoryListBK
      },
      //获取热门专业
      async getHotSpecialty () {
        this.hotSpecialtyList = (await API.Specialty.SpecialtyHotList({Number: 3})).data
      }
    }
  }
</script>

<style scoped lang="less">
  .top {
    padding: 1.5rem 0 3rem;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 45%;
      min-width: 28rem;
      display: flex;
      &-input {
        width: 80%;
      }
      &-button {
        width: 20%;
      }
    }
    &-right {
      width: 40%;
      min-width: 28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        color: var(--text-font-color);
        font-size: var(--title-font-size);
      }
      &-box {
        width: 60%;
        display: flex;
        justify-content: space-between;
        color: rgba(var(--themecolor),1);
        font-size: var(--text-font-size);
        &-item {
          cursor: pointer;
        }
      }
    }
  }
  .content{
    border-radius: .5rem;
    overflow: hidden;
    &-top{
      height: 4rem;
      padding: .5rem 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(var(--themecolor),0.75);
      color: #fff;
      font-size: var(--title-font-size);
      &-name{

      }
      &-box{
        display: flex;
        text-align: center;
        &-item{
          width: 10rem;
          padding: 1.2rem 0;
          border-radius: .2rem;
          cursor: pointer;
        }

        .is-active{
          background-color: #fff;
          color: rgba(var(--themecolor),0.75);
        }
      }
    }
    &-box{
      background-color: #fff;
      padding: 1rem 1rem 3rem;
      .main{
        display: flex;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid var(--color-grey);

        &-name{
          width: 15rem;
          font-size: var(--text-font-size);
          font-weight: 700;
          color: var(--text-font-color);
        }
        &-box{
          width: calc(100% - 15rem);
          display: flex;
          flex-wrap: wrap;
          margin-left: 1.5rem;
          &-item{
            line-height: 2;
            margin-right: 1.5rem;
            // border-bottom: 1px solid var(--color-grey);
            font-size: var(--text-font-size);
            color: var(--text-font-color);
            &:hover{
              cursor: pointer;
              color: rgba(var(--themecolor),0.75);
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
.app-container .el-input__inner:focus,.app-container .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.app-container .el-button--primary,.app-container .el-button--primary.is-plain{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>